@import '~bloko/common/styles/media/_screen';
@import '~bloko/common/styles/colors';

.read-more {
    @media @screen-s, @screen-lt-s {
        &::after {
            content: '';
            display: block;
            margin: 0 15px;
            padding: 20px 0 0;
            border-bottom: 1px solid @color-gray-20;
        }
    }
}

.list {
    margin: 0 -15px;
    row-gap: 20px;
    display: flex;
    flex-wrap: wrap;

    @media @screen-gt-xs {
        row-gap: 32px;
    }
}

.item-container {
    @media @screen-lt-s {
        width: 100%;
    }
}
