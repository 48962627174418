@import '~bloko/common/styles/spacing-base';
@import '~bloko/common/styles/text-base';

.lead-small {
    /* stylelint-disable-next-line declaration-property-value-allowed-list */
    font-size: 17px;
    line-height: 27px;
}

.lead {
    .bloko-text-extra-large();
}
