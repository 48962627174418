@import '~bloko/common/styles/media/_screen';
@import '~bloko/common/styles/text-base';

@media @screen-gt-m {
    .container {
        width: 690px;
    }
}

.image {
    vertical-align: top;
    width: 100%;
}

.description {
    .bloko-text-small();
    .bloko-text-tertiary();

    line-height: 20px;
    margin: 5px 0 -10px;
}
