@import '~bloko/common/styles/media/_screen';
@import '~bloko/common/styles/text-base';

.landing-content {
    .bloko-text-large();

    margin: 0 auto;
    padding: 0 15px;

    @media @screen-gt-s {
        max-width: 930px;
    }

    @media @screen-gt-m {
        max-width: 1250px;
    }
}

.cms-content {
    overflow-wrap: break-word;
    word-wrap: break-word;
}
