@import '~bloko/common/styles/media/_screen';
@import '~bloko/common/styles/colors';

.sidebar {
    padding: 20px 0;
    border-left: 0;

    @media @screen-gt-s {
        padding: 0 0 0 30px;
        border-left: 1px solid @color-gray-20;
    }
}
