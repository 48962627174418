@import '~bloko/common/styles/media/_screen';

.cms-cut {
    margin-top: 12px;
    margin-bottom: 20px;
    font-size: 16px;
}

.cms-cut-landing {
    box-sizing: border-box;

    @media @screen-gt-xs {
        max-width: 610px;
        margin-right: auto;
        margin-left: auto;
    }

    @media @screen-gt-m {
        max-width: 770px;
    }
}
