@import '@hh.ru/design-tokens/build/less/variables';
@import '_abstract-icon';
@import '_abstract-control';
@import 'colors';
@import 'color-utils';

@_button-padding-scale-small: 0 12px;
@_button-padding-scale-medium: 0 12px;
@_button-padding-scale-large: 0 16px;
@_button-active-padding: 2px;

@button-border-width: 1px;

@_button-active-box-shadow: inset 0 2px 0 fade(@color-gray-80, 35%);

@_button-group-vertical-space: 10px;

._button-hover-color-property(@property, @color, @color-from-css-vars: '') when (@color-from-css-vars = '') {
    ._move-color-hsv-value(@property, @color, -10);
}

._button-hover-color-property(@property, @color, @color-from-css-vars: '') when not (@color-from-css-vars = '') {
    @{property}: @color-from-css-vars;
}

._button-active-color-property(@property, @color, @color-from-css-vars: '') when (@color-from-css-vars = '') {
    ._move-color-hsv-value(@property, @color, -20);
}

._button-active-color-property(@property, @color, @color-from-css-vars: '') when not (@color-from-css-vars = '') {
    @{property}: @color-from-css-vars;
}

.bloko-button-base() {
    display: inline-block;
    box-sizing: border-box;
    margin: 0;
    vertical-align: middle;

    text-decoration: none;
    user-select: none;
    white-space: nowrap;
    -webkit-appearance: none;

    cursor: pointer;

    &::-moz-focus-inner {
        border: 0;
    }
}

.bloko-button(@prefix) {
    .bloko-button-base();

    font-weight: @bloko-typography-button-medium-font-weight;
    font-size: @bloko-typography-button-medium-font-size;

    height: @control-height-scale-medium;

    position: relative;
    padding: @_button-padding-scale-medium;
    border: @button-border-width solid;
    border-radius: @bloko-border-radius-button;

    font-family: inherit;
    line-height: @control-height-scale-medium - @button-border-width * 2;

    &:hover {
        text-decoration: none;
    }

    &:focus {
        outline: none;
        text-decoration: none;
    }

    &:active {
        text-decoration: none;
        outline: none;
        box-shadow: @_button-active-box-shadow;
        padding-top: @_button-active-padding;
    }

    &.@{prefix}_pressed {
        box-shadow: @_button-active-box-shadow;
        padding-top: @_button-active-padding;
    }

    [disabled] &,
    &[disabled] {
        cursor: default;
        pointer-events: none;

        .bloko-icon {
            opacity: 0.5;
        }
    }
}

/* stylelint-disable selector-max-compound-selectors */
.bloko-button-color(
    @prefix,
    @appearance,
    @default-color,
    @hover-color: @default-color,
    @active-color: @default-color,
    @disabled-color: @default-color
)
    when
    (@appearance = filled) {
    color: @default-color;

    &:hover,
    &:focus {
        color: @hover-color;
    }

    &:active,
    &:visited,
    &.@{prefix}_pressed {
        color: @active-color;
    }

    [disabled] &,
    &[disabled] {
        &:not(.@{prefix}_pressed) {
            color: @disabled-color;
        }
    }
}
/* stylelint-enable selector-max-compound-selectors */

.bloko-button-color(
    @prefix,
    @appearance,
    @default-color,
    @hover-color: @default-color,
    @active-color: @default-color,
    @disabled-color: @default-color
)
    when
    not
    (@appearance = filled) {
    &:not(.@{prefix}_pressed) {
        &.@{prefix}_appearance-@{appearance},
        &.@{prefix}_appearance-@{appearance}:visited {
            color: @default-color;
        }

        &.@{prefix}_appearance-@{appearance}:hover,
        &.@{prefix}_appearance-@{appearance}:focus {
            color: @hover-color;
        }

        &.@{prefix}_appearance-@{appearance}:active {
            color: @active-color;
        }

        [disabled] &.@{prefix}_appearance-@{appearance},
        &.@{prefix}_appearance-@{appearance}[disabled] {
            color: @disabled-color;
        }
    }
}

/* stylelint-disable selector-max-compound-selectors */
.bloko-button-border-color(
    @prefix,
    @appearance,
    @color,
    @color-hover: '',
    @color-active: '',
    @color-disabled: @color
)
    when
    (@appearance = filled) {
    border-color: @color;

    &:hover,
    &:focus {
        ._button-hover-color-property(border-color, @color, @color-hover);
    }

    &:global(.focus-visible) {
        .abstract-control-focus-visible();
    }

    &:active,
    &.@{prefix}_pressed {
        ._button-active-color-property(border-color, @color, @color-active);

        box-shadow: @_button-active-box-shadow;
    }

    [disabled] &,
    &[disabled] {
        &:not(.@{prefix}_pressed) {
            border-color: @color-disabled;
        }
    }
}
/* stylelint-enable selector-max-compound-selectors */

.bloko-button-border-color(
    @prefix,
    @appearance,
    @color,
    @color-hover: '',
    @color-active: '',
    @color-disabled: @color
)
    when
    not
    (@appearance = filled) {
    &:not(.@{prefix}_pressed) {
        &.@{prefix}_appearance-@{appearance} {
            border-color: @color;
        }

        [disabled] &.@{prefix}_appearance-@{appearance},
        &.@{prefix}_appearance-@{appearance}[disabled] {
            border-color: @color-disabled;
        }

        &.@{prefix}_appearance-@{appearance}:hover,
        &.@{prefix}_appearance-@{appearance}:focus {
            border-color: @color-hover;
        }

        &.@{prefix}_appearance-@{appearance}:active {
            border-color: @color-active;
        }
    }
}

/* stylelint-disable selector-max-compound-selectors */
.bloko-button-background-color(
    @prefix,
    @appearance,
    @color,
    @color-hover: '',
    @color-active: '',
    @color-disabled: @color
)
    when
    (@appearance = filled) {
    background-color: @color;

    &:hover,
    &:focus {
        ._button-hover-color-property(background-color, @color, @color-hover);
    }

    &:active,
    &.@{prefix}_pressed {
        ._button-active-color-property(background-color, @color, @color-active);
    }

    [disabled] &,
    &[disabled] {
        background-color: @color-disabled;

        &.@{prefix}_pressed {
            ._button-active-color-property(background-color, @color, @color-active);

            opacity: 0.5;
        }
    }
}
/* stylelint-enable selector-max-compound-selectors */

.bloko-button-background-color(
    @prefix,
    @appearance,
    @color,
    @color-hover: '',
    @color-active: '',
    @color-disabled: @color
)
    when
    not
    (@appearance = filled) {
    &:not(.@{prefix}_pressed) {
        &.@{prefix}_appearance-@{appearance} {
            background-color: @color;
        }

        [disabled] &.@{prefix}_appearance-@{appearance},
        &.@{prefix}_appearance-@{appearance}[disabled] {
            background-color: @color-disabled;
        }

        &.@{prefix}_appearance-@{appearance}:hover,
        &.@{prefix}_appearance-@{appearance}:focus {
            ._button-hover-color-property(background-color, @color, @color-hover);
        }

        &.@{prefix}_appearance-@{appearance}:active {
            ._button-active-color-property(background-color, @color, @color-active);
        }
    }
}

/* stylelint-disable selector-max-compound-selectors */
.bloko-button-icon-color(
    @prefix,
    @appearance,
    @color,
    @color-disabled: @color,
    @color-hover: '',
    @color-active: ''
)
    when
    (@appearance = filled) {
    --bloko-icon-color: @color;

    &:hover,
    &:focus {
        --bloko-icon-color: @color-hover;
    }

    &:active,
    &.@{prefix}_pressed {
        --bloko-icon-color: @color-active;
    }

    [disabled] &,
    &[disabled] {
        --bloko-icon-color: @color-disabled;

        &.@{prefix}_pressed {
            --bloko-icon-color: @color-active;
        }
    }
}
/* stylelint-enable selector-max-compound-selectors */

.bloko-button-icon-color(
    @prefix,
    @appearance,
    @color,
    @color-disabled: @color,
    @color-hover: '',
    @color-active: ''
)
    when
    not
    (@appearance = filled) {
    &:not(.@{prefix}_pressed) {
        &.@{prefix}_appearance-@{appearance} {
            --bloko-icon-color: @color;
        }

        [disabled] &.@{prefix}_appearance-@{appearance},
        &.@{prefix}_appearance-@{appearance}[disabled] {
            --bloko-icon-color: @color-disabled;
        }

        &.@{prefix}_appearance-@{appearance}:hover,
        &.@{prefix}_appearance-@{appearance}:focus {
            --bloko-icon-color: @color-hover;
        }

        &.@{prefix}_appearance-@{appearance}:active {
            --bloko-icon-color: @color-active;
        }
    }
}

.bloko-button-colors(
    @prefix,
    @appearance,
    @background-color,
    @background-color-hover,
    @background-color-active,
    @background-color-disabled,
    @label-color,
    @label-color-hover,
    @label-color-active,
    @label-color-disabled,
    @border-color,
    @border-color-hover,
    @border-color-active,
    @border-color-disabled,
    @icon-color-default,
    @icon-color-disabled,
    @icon-color-hover,
    @icon-color-active,
) {
    .bloko-button-color(
        @prefix,
        @appearance,
        @label-color,
        @label-color-hover,
        @label-color-active,
        @label-color-disabled
    );
    .bloko-button-border-color(
        @prefix,
        @appearance,
        @border-color,
        @border-color-hover,
        @border-color-active,
        @border-color-disabled
    );
    .bloko-button-background-color(
        @prefix,
        @appearance,
        @background-color,
        @background-color-hover,
        @background-color-active,
        @background-color-disabled
    );

    .bloko-button-icon-color(
        @prefix,
        @appearance,
        @icon-color-default,
        @icon-color-disabled,
        @icon-color-hover,
        @icon-color-active,
    );
}

.bloko-button-scale-small() {
    font-weight: @bloko-typography-button-small-font-weight;
    font-size: @bloko-typography-button-small-font-size;

    height: @control-height-scale-small;
    padding: @_button-padding-scale-small;

    font-family: inherit;
    line-height: @control-height-scale-small - @button-border-width * 2;
}

.bloko-button-scale-large() {
    font-weight: @bloko-typography-button-large-font-weight;
    font-size: @bloko-typography-button-large-font-size;

    height: @control-height-scale-large;
    padding: @_button-padding-scale-large;

    font-family: inherit;
    line-height: @control-height-scale-large - @button-border-width * 2;

    &:active {
        padding-top: @_button-active-padding;
    }
}

.bloko-button-icon-only(@control-height) {
    width: @control-height;
    height: @control-height;

    .bloko-button__icon {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -@icon-size-16 / 2;
        margin-left: -@icon-size-16 / 2;
        line-height: 0;
    }

    &::after {
        display: inline-block;
        content: '';
        width: @icon-size-16;
        height: @icon-size-16;
    }

    &:active .bloko-button__icon,
    &.bloko-button_pressed .bloko-button__icon {
        padding-top: @_button-active-padding;
    }
}
