@import '~bloko/common/styles/media/_screen';

.container {
    box-sizing: border-box;

    @media @screen-gt-xs {
        max-width: 610px;
        margin-right: auto;
        margin-left: auto;
    }

    @media @screen-gt-m {
        max-width: 770px;
    }
}
