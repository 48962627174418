@import '@hh.ru/magritte-ui/base-variables';
@import '~bloko/common/styles/media/_screen';
@import '~bloko/common/styles/colors';

.container {
    max-width: 450px;
}

.logo {
    float: right;
    max-width: 60px;
    max-height: 60px;
    margin-top: @magritte-core-space-1-x;

    @media @screen-gt-xs {
        max-width: 100px;
        max-height: 100px;
    }
}

.links-container {
    display: flex;
    column-gap: @magritte-core-space-5-x;
    flex-wrap: wrap;
}

.participants {
    padding: 30px 15px;
    background-color: @color-gray-10;

    @media @screen-lt-m {
        margin-right: -15px;
        margin-left: -15px;
    }
}

.interview-count {
    color: @color-gray-50;
}
