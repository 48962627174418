@import '~bloko/common/styles/media/_screen';

.top-margin {
    margin-top: 32px;

    @media @screen-gt-xs {
        margin-top: 44px;
    }
}

.top-margin-small {
    margin-top: 24px;
}

.bottom-margin {
    margin-bottom: 24px;
}
