@import '~bloko/common/styles/media/_screen';

.lead-with-image {
    display: flex;
    margin: 0 -15px;

    @media @screen-lt-s {
        flex-direction: column-reverse;
    }
}

.author {
    @media @screen-lt-s {
        display: flex;
        align-items: center;
        padding-top: 10px;
        margin-bottom: 24px;
    }
}

.author-image {
    width: 60px;
    height: 60px;
    margin-bottom: 10px;
    vertical-align: top;
    border-radius: 100%;

    @media @screen-lt-s {
        margin-bottom: 0;
        margin-right: 13px;
    }
}
