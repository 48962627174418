@import './colors';

.put-focus-color-property(@property, @color) when (lightness(@color) >= 50%) {
    @{property}: lighten(@color, (100 - lightness(@color)) * 0.3);
}

.put-focus-color-property(@property, @color) when (lightness(@color) < 50%) {
    @{property}: lighten(@color, 20%, relative);
}

.color-highlight() {
    background: @color-blue-60;
    color: @color-white;
}

._move-color-hsl-lightness(@property, @color, @offset) {
    /* stylelint-disable-next-line hhru/less-variable-value-disallowed-list */
    @{property}: hsl(floor(hue(@color)), floor(saturation(@color)), floor(lightness(@color) + @offset));
}

._move-color-hsv-value(@property, @color, @offset) {
    @{property}: hsv(hsvhue(@color), hsvsaturation(@color), hsvvalue(@color) + @offset);
}
