@import '~bloko/common/styles/media/_screen';

.placeholder-container {
    --container-min-width: 320px;

    min-height: calc(var(--container-min-width) * 0.645);

    @media @screen-gt-xs {
        --container-min-width: 640px;
    }

    @media @screen-gt-s {
        --container-min-width: 720px;
    }

    @media @screen-gt-m {
        --container-min-width: 770px;
    }
}
