@import '~bloko/common/styles/colors';
@import '~bloko/common/styles/text-base';

.container {
    .bloko-text-medium();

    line-height: 20px;
    color: @color-gray-80;
    vertical-align: middle;
}

.title {
    display: block;
    word-wrap: break-word;
}

.compensation {
    display: block;
}

.company {
    .bloko-text-secondary();
    .bloko-text-medium();
}
