@import '~bloko/common/styles/colors';
@import '~bloko/common/styles/media/_screen';

.mobile-border-bottom {
    border-bottom: 1px solid @color-gray-20;

    @media @screen-gt-xs {
        border-bottom-width: 0;
    }
}

.interviews-block {
    border-top: 1px solid @color-gray-20;
}

.interviews-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;

    @media @screen-gt-xs {
        row-gap: 16px;
    }
}

.interview-layout {
    display: flex;
    gap: 8px;
    align-items: center;

    @media @screen-gt-xs {
        flex-direction: column;
        align-items: flex-start;
    }
}

.interview-image {
    width: 80px;

    @media @screen-gt-xs {
        width: 100%;
        vertical-align: top;
    }
}
