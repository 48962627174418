@import '~bloko/common/styles/_abstract-icon';
@import '~bloko/common/styles/button-base';
@import '~bloko/common/styles/spacing-base';

@vote-button-color: @color-gray-50;
@vote-button-bg-color: @color-gray-10;

@vote-button-color-pressed: @color-blue-60;
@vote-button-bg-color-pressed: @color-blue-10;

@vote-button-mixin-prefix: ~'article-rating-vote-button';

.vote-button-mixin(@color, @bg-color) {
    .bloko-button(@vote-button-mixin-prefix);
    .bloko-button-color(@vote-button-mixin-prefix, filled, @color);
    .bloko-button-border-color(@vote-button-mixin-prefix, filled, @bg-color);
    .bloko-button-background-color(@vote-button-mixin-prefix, filled, @bg-color);
    .bloko-button-icon-color(@vote-button-mixin-prefix, filled, @color, @color, @color, @color);

    padding: 0 11px;
    border-radius: calc(@control-height-scale-medium / 2);

    height: @control-height-scale-medium;
    min-width: @control-height-scale-medium;
}

.vote-button {
    .vote-button-mixin(@vote-button-color, @vote-button-bg-color);
}

.vote-button_pressed {
    .vote-button-mixin(@vote-button-color-pressed, @vote-button-bg-color-pressed);
}

.vote-button-text {
    padding-left: @spacing-base * 2;
}
